import React, {useEffect, useState} from 'react';
import {Button, Divider, message, Space, Table} from 'antd';
import {Link} from 'react-router-dom';
import clientAPI from "../../service/ApiService";
import {API_USERS} from "../../constants";
import AddUserHotelModal from "./AddUserHotelModal";
import {getDetailUsersEndpoint, isAdmin} from "../../service/RoleService";

function UserHotelsTable({user, refresh}) {

    const [hotels, setHotels] = useState([])
    const [loading, setLoading] = useState(false);
    const [hotelModal, setHotelModal] = useState(0);

    useEffect(() => {
        if(!user)
            return;
        getHotels()
    }, [user, refresh]);// eslint-disable-line react-hooks/exhaustive-deps

    const getHotels = () => {
        setLoading(true);

        clientAPI.get(getDetailUsersEndpoint() + "/" + user.id + "/hotels")
            .then(response => {
                let h = response?.data?.data;
                if(!h){
                    return false
                }

                setHotels(h);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
            })
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: id => <Link to={"/hotel/" + id}>
                <p style={{ color:"#1e88e5" }}>{id}</p>
            </Link>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, hotel) => <Link to={"/hotel/" + hotel.id}>
                <p style={{ color:"#1e88e5" }}>{hotel.name}</p>
            </Link>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" danger onClick={(e)=>removeHotel(record.id)}>Remove</Button>
                </Space>
            ),
        },
    ];

    const removeHotel = (hotelId) => {

        clientAPI.delete(API_USERS + "/" + user.id + "/hotel/" + hotelId)
            .then(response => {
                getHotels();
            })
            .catch(error => {
                setLoading(false);
                let status = error?.response?.data?.statusCode?.code;
                if(status === -6002){
                    message.error('You are trying to delete a user that has some information attached.');
                }else{
                    message.error('Error deattaching the user from that hotel');
                }
            })
    };

    return (
        <div className={"user-hotels-table"}>
            <Divider plain>Hotels</Divider>
            {isAdmin() &&
                <Button className={"create-cta"}
                        type={"primary"}
                        onClick={() => setHotelModal(hotelModal + 1)}>
                    Add hotel
                </Button>
            }
            <Table
                columns={columns}
                dataSource={hotels}
                loading={loading}
                pagination={false}
            />
            <AddUserHotelModal
                selectedHotels={hotels}
                open={hotelModal}
                doRefresh={getHotels}
                user={user}
            />
        </div>
    );
}

export default UserHotelsTable;
