import React from 'react';
import {Button, Form} from "antd";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
function Editor({ onChange, onSubmit, submitting, value }){
    let modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };
    let formats = [
            'bold', 'italic', 'underline', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ];


    return (
        <div>
            <>
                <Form.Item>
                    <ReactQuill theme="snow" value={value} onChange={onChange}     modules={modules} formats={formats} />

                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                        Add Comment
                    </Button>
                </Form.Item>
            </>
        </div>
    )
};

export default Editor;
