/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, message, Modal, Select} from "antd";
import clientAPI from "../../service/ApiService";
import {API_HOTELS, API_SUBMITS} from "../../constants";
import TextArea from "antd/es/input/TextArea";

function SubmitRequestModal({open, submitRequest, doRefresh}){

    const [loading, setLoading] = useState(false);

    const [hotelsLoading, setHotelsLoading] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [form] = Form.useForm();

    const { Option } = Select;

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        getHotels();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        setLoading(true);
        let request = {};
        if(submitRequest === null){
            request = clientAPI.post(API_SUBMITS, data);
        }else{
            request = clientAPI.put(API_SUBMITS + "/" + submitRequest.id, data);
        }
        request
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    const getHotels = (search=null) => {
        setHotelsLoading(true);

        clientAPI.get(API_HOTELS)
            .then(response => {

                let hotels = response.data?.data;
                if(!hotels || hotels.length <= 0 ){
                    setHotels([])
                    return false
                }
                setHotels(hotels);
                setHotelsLoading(false);
            })
            .catch(error => {
                message.error("Error loading the hotels");
                setHotelsLoading(false);
            })
    }

    return (
        <Modal
            title="Create submit request"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["hotel"]: submitRequest?.hotel,
                    ["users"]: submitRequest?.users,
                    ["content"]: submitRequest?.content,
                }}
            >
                <p>This will send an email to all the people with a link to start adding the USP's or comments.</p>
                <Form.Item
                    label="Hotel"
                    name="hotel"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        placeholder="Select hotels"
                        loading={hotelsLoading}
                    >
                        {hotels?.map((hotel, index) =>
                            <Option key={index} value={hotel.id}>{hotel.name}</Option>
                        )}
                    </Select>
                </Form.Item>


                <Form.Item
                    label="Please add the description"
                    name="content"
                    rules={[{ required: true }]}
                >
                    <TextArea />
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default SubmitRequestModal;
