import React from 'react'
import {Link} from "react-router-dom";
import moment from 'moment';

function Row({ usp }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <Link to={"/submitRequest/" + usp?.SubmitRequest?.id}>
                    <p style={{ color:"#1e88e5" }}>{usp?.SubmitRequest?.id}</p>
                </Link>
            </td>
            {/*<td className="">*/}
            {/*    <Link to={"/submitRequest/" + usp?.SubmitRequest?.id}>*/}
            {/*        {usp?.SubmitRequest?.Hotel?.name}*/}
            {/*    </Link>*/}
            {/*</td>*/}
            <td className="">
                <Link to={"/submitRequest/" + usp?.SubmitRequest?.id}>
                    {usp?.landingUrl}
                </Link>
            </td>
            <td className="">
                {moment(usp?.SubmitRequest?.createdAt).format("DD-MM-YYYY")}
            </td>
        </tr>
    )
}

export default Row