import React from 'react'
import {Link} from "react-router-dom";

function Row({ country }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <Link to={"/country/" + country?.id}>
                    <p style={{ color:"#1e88e5" }}>{country.id}</p>
                </Link>
            </td>
            <td className="">
                <Link to={"/country/" + country?.id}>
                    {country.name}
                </Link>
            </td>
        </tr>
    )
}

export default Row