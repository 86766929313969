import React, {useEffect, useState} from 'react';
import clientAPI from "../../service/ApiService";
import {Spin, message} from 'antd';
import Row from "./CountryRow";
import {API_COUNTRIES} from "../../constants";

function CountryTable({refresh}) {
    
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getCountries = () => {
        setLoading(true);

        clientAPI.get(API_COUNTRIES)
            .then(response => {

                let countries = response.data?.data;
                if(!countries || countries.length <= 0 ){
                    setCountries([])
                    return false
                }
                setCountries(countries);
                setLoading(false);
            })
            .catch(error => {
                message.error("Error loading the countries");
                setLoading(false);
            })
    }

    return (
        <div>
            {loading && <Spin/>}
            {countries &&
            <div className={"table-wrapper ant-table"}>
                <table className="table">
                    <thead className={"ant-table-thead"}>
                    <tr>
                        <th scope="col" style={{ color:"black" }}>Id</th>
                        <th scope="col">Name</th>
                    </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                    {countries.map((country, index) =>
                        <Row key={index} country={country} />
                    )}
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}


export default CountryTable;