import React from 'react'

function Pagination({sizePerPage, totalSize, page, goToPage}) {

    return (
        <div className={"ant-pagination ant-table-pagination ant-table-pagination-right"}>
            <ul className="pagination">
                { sizePerPage && sizePerPage && Math.ceil(totalSize/sizePerPage) > 1 &&
                [...Array( Math.ceil(totalSize/sizePerPage) )].map((e, i) =>
                    <li key={i} className={i+1 === page ? "selected ant-pagination-item ant-pagination-item-active" : "ant-pagination-item"}>
                        <button onClick={() => goToPage(i+1)}>
                            {i+1}
                        </button>
                    </li>
                )
                }
            </ul>
        </div>
    )
}

export default Pagination


