/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import clientAPI from "../../../service/ApiService";
import {API_USER_USPS} from "../../../constants";

function USPModal({open, submitRequest, usp, doRefresh}){

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if(open <= 0)
            return;

        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        if(usp === null){
            form.setFieldsValue({
                ["landingUrl"]: null,
                ["description"]: null,
            })
        }else{
            form.setFieldsValue({
                ["landingUrl"]: usp.landingUrl,
                ["description"]: usp.description,
            })
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        data = {...data, submitRequestId: submitRequest.id};
        setLoading(true);
        let request = {};
        if(usp === null){
            request = clientAPI.post(API_USER_USPS, data);
        }else{
            request = clientAPI.put(API_USER_USPS + "/" + usp.id, data);
        }

        request
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? error.response?.data?.data)
            })
    };

    const onFinishFailed = () => {};

    return (
        <Modal
            title="Edit/Create USP"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["description"]: usp?.description,
                    ["landingUrl"]: usp?.landingUrl,
                }}
            >
                <p>This will send an email to all the people with a link to start adding the USP's or comments.</p>

                <Form.Item
                    label="Please add a landing url"
                    name="landingUrl"
                    rules={[{ required: true, type: 'url' }]}
                >
                    <Input autoFocus={true} />
                </Form.Item>

                <Form.Item
                    label="Please add the description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <TextArea />
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default USPModal;
