import React from 'react'
import {Button} from "antd";

function Row({ category, update }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <p style={{ color:"#1e88e5" }}>{category.id}</p>
            </td>
            <td className="">
                {category.name}
            </td>
            <td className="">
                <Button onClick={()=>update(category)}>Update</Button>
            </td>
        </tr>
    )
}

export default Row