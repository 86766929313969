import React from 'react'
import {Button, Form, Input, message} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import {API_URL} from "../../../constants";
import axios from 'axios';

function SetpassPage() {
    const location = useLocation();
    const tokenID = new URLSearchParams(location.search).get('TOKEN');
    const history = useHistory();

    const onFinish = (value) => {
        axios.post(API_URL + "/setpass", {
            "token": tokenID,
            "password": value.password
        })
            .then(response => {
                history.push("/login");
            })
            .catch(error =>{
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg)
            })
    };

    return (
        <div className={"login-page setpass"}>
            <div style={{width: '400px'}}>
                <h3 style={{marginBottom: '24px'}}>Set Password</h3>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Password"
                        labelAlign="left"
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { min: 8, message: 'Password must be at least 8 characters.'},
                            { pattern: new RegExp("(?=.*[A-Z])"), message: 'Password must be at least one Uppercase letter.'},
                            { pattern: new RegExp("(?=.*\\d)"), message: 'Password must be at least one digit.'},
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{}} style={{marginTop: '15px'}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default SetpassPage
