import React from 'react';
import USPTable from "./USPTable";
import {isAdmin} from "../../service/RoleService";
import LogTable from "./LogTable";
import {Col, Row} from "antd";

function DashboardPage(){

    return (
        <div>
            <h2>Dashboard</h2>
            <Row justify="space-between">
                <Col span={11}>
                    {isAdmin() &&
                    <USPTable />
                    }
                </Col>
                <Col span={11}>
                    {isAdmin() &&
                    <LogTable />
                    }
                </Col>
            </Row>
        </div>
    );
};

export default DashboardPage;
