import React, {useContext} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import LoginPage from "./components/Public/Login/LoginPage";
import BasePage from "./components/BasePage";
import {Context} from "./app/store";
import RegisterPage from "./components/Public/Register/RegisterPage";
import ForgotPage from "./components/Public/Forgot/ForgotPage";
import SetpassPage from "./components/Public/Setpass/SetpassPage";

function AppRouter() {

    const [userState] = useContext(Context);

    const isAuthenticated = () => {
        return userState.jwtToken && userState.jwtToken.length >= 0;
    };

    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register" component={RegisterPage}/>
                    <Route exact path="/forgot" component={ForgotPage}/>
                    <Route path="/resetpass" component={SetpassPage}/>
                    <Route path="/">
                        {!isAuthenticated() ? <Redirect to="/login" /> : <Route path="/" component={BasePage} />}
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};

export default AppRouter;
