import React from 'react';
import {Tag} from "antd";


function TicketTag({status}) {

    return (
        <div>
            {status === "submitted" && <Tag color="orange">Submitted</Tag>}
            {status === "pending_review" && <Tag color="purple">Pending Review</Tag>}
            {status === "ongoing" && <Tag color="lime">On-going</Tag>}
            {status === "qa" && <Tag color="green">QA</Tag>}
            {status === "revision" && <Tag color="cyan">Revision</Tag>}
            {status === "completed" && <Tag color="blue">Completed</Tag>}
            {status === "approved" && <Tag color="geekblue">Approved</Tag> }
        </div>
    );
}

export default TicketTag;
