import React, {useState} from 'react';
import HotelTable from './HotelTable'
import {Button} from "antd";
import HotelModal from "./HotelModal";
import {isAdmin} from "../../service/RoleService";

function HotelPage(){

    const [hotelModal, setHotelModal] = useState(0);
    const [refresh, setRefresh] = useState(0)

    const doRefresh = () => {
        setRefresh(refresh+1);
    }

    return (
        <div>
            <h2>Hotels</h2>
            { isAdmin() &&
                <Button className={"create-cta"} type={"primary"} onClick={() => setHotelModal(hotelModal+1)} >Create</Button>
            }
            <HotelTable refresh={refresh} />
            <HotelModal
                hotel={null}
                open={hotelModal}
                doRefresh={doRefresh}
            />

        </div>
    )
};

export default HotelPage;
