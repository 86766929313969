import React, {useEffect, useState} from 'react';
import {Divider, message, Table} from 'antd';
import {Link} from 'react-router-dom';
import clientAPI from "../../service/ApiService";
import {API_USER_HOTELS} from "../../constants";

function UserSubmitRequestsTable({submitRequest, refresh}) {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSubmitRequestUsers();
    }, [refresh]);// eslint-disable-line react-hooks/exhaustive-deps

    const getSubmitRequestUsers = () => {
        setLoading(true);

        clientAPI.get(API_USER_HOTELS + "/" + submitRequest.HotelId + "/users")
            .then(response => {
                let h = response?.data?.data;
                if(!h){
                    return false
                }
                setUsers(h);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
            })
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: id => <Link to={"/user/" + id}>
                <p style={{ color:"#1e88e5" }}>{id}</p>
            </Link>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, user) => <Link to={"/user/" + user.id}>
                <p style={{ color:"#1e88e5" }}>{user.name}</p>
            </Link>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, user) => <Link to={"/user/" + user.id}>
                <p style={{ color:"#1e88e5" }}>{user.email}</p>
            </Link>,
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Button type="link" danger onClick={(e)=>removeSubmitRequest(record.id)}>Remove</Button>
        //         </Space>
        //     ),
        // },
    ];

    // const removeSubmitRequest = (userId) => {
    //
    //     clientAPI.delete(API_SUBMITS + "/" + submitRequest.id + "/user/" + userId)
    //         .then(response => {
    //             getSubmitRequestUsers();
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             let status = error?.response?.data?.statusCode?.code;
    //             if(status === -6002){
    //                 message.error('You are trying to delete a user that has some information attached.');
    //             }else{
    //                 message.error('Error deattaching the user from that submitRequest');
    //             }
    //         })
    // };

    return (
        <div className={"user-submitRequests-table"}>
            <Divider plain>Users</Divider>
            {/*<Button className={"create-cta"}*/}
            {/*        type={"primary"}*/}
            {/*        onClick={() => setSubmitRequestModal(submitRequestModal+1)} >*/}
            {/*    Add user*/}
            {/*</Button>*/}

            <Table
                columns={columns}
                dataSource={users}
                loading={loading}
                pagination={false}
            />
            {/*<AddUserSubmitRequestModal*/}
            {/*    selectedSubmitRequests={submitRequests}*/}
            {/*    open={submitRequestModal}*/}
            {/*    doRefresh={getSubmitRequests}*/}
            {/*    user={user}*/}
            {/*/>*/}
        </div>
    );
}

export default UserSubmitRequestsTable;
