/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, message, Modal, Select} from "antd";
import clientAPI from "../../service/ApiService";
import {API_USERS} from "../../constants";
import {getTicketsEndpoint} from "../../service/RoleService";

function TicketModal({open, ticket, doRefresh}){

    const [loading, setLoading] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        getUsers();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        data = {...data, ticketId: ticket.id}

        setLoading(true);
        clientAPI.post(getTicketsEndpoint() + "/assign", data)
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    const getUsers = () => {
        setUsersLoading(true);

        clientAPI.get(API_USERS)
            .then(response => {

                let users = response.data?.data;
                if(!users || users.length <= 0 ){
                    setUsers([])
                    return false
                }
                setUsers(users);
                setUsersLoading(false);
            })
            .catch(error => {
                message.error("Error loading the hotels");
                setUsersLoading(false);
            })
    }

    return (
        <Modal
            title="Assign users"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["users"]: ticket?.Users.map(x=>x.id),
                }}
            >
                <Form.Item
                    label="Assigned users to this ticket"
                    name="users"
                >
                    <Select
                        showSearch
                        placeholder="Select users"
                        loading={usersLoading}
                        mode="multiple"
                    >
                        {users?.map((user, index) =>
                            <Option key={index} value={user.id}>{user.name}</Option>
                        )}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default TicketModal;
