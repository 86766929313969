import React, {useEffect, useState} from 'react';
import clientAPI from "../../service/ApiService";
import {Alert, Col, Input, Row, Select} from 'antd';
import TicketRow from "./TicketRow";

import Pagination from "../Common/Pagination";
import {getTicketsEndpoint} from "../../service/RoleService";
const { Option } = Select;

function TicketTable({refresh}) {

    const initialState = {
        page: 1,
        tickets: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false
    };
    const [state, setState] = useState(initialState);
    const [filters, setFilters] = useState({
        type: "all"
    });

    useEffect(() => {
        getTickets()
    }, [state.page, state.sizePerPage, refresh, filters]);// eslint-disable-line react-hooks/exhaustive-deps

    const getTickets = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        });

        let requestUrl = getTicketsEndpoint() +
            "?page=" + state.page +
            "&limit=" + state.sizePerPage +
            (search ? "&keyword=" + encodeURIComponent(search) : "")
        ;

        if(filters.type === "Seo" || filters.type === "Analytics"){
            requestUrl = requestUrl + "&type=" + filters.type;
        }

        clientAPI.get(requestUrl)
            .then(response => {

                let tickets = response.data?.data;

                if(!tickets || tickets.length <= 0 ){
                    setState({...state,
                        tickets:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page
                    });
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    tickets: tickets,
                    totalSize: response.data.pagination.total
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    tickets: [],
                    totalSize: null
                })
            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getTickets(searchText)
            }, 300)
        )
    }

    const handleFilterChange = (value) => {
        setFilters({
            ...filters,
            type: value
        })
    };

    return (
        <div>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <Row justify={"space-between"}>
                <Col span={12}>
                    <Search placeholder="Search ticket by id or name"
                            loading={state.loading}
                            onChange={(e) => setSearch(e)}
                    />
                </Col>
                <Col span={8}>
                    <Select
                        defaultValue="all"
                        style={{
                            width: "100%",
                        }}
                        allowClear
                        onChange={handleFilterChange}
                    >
                        <Option value="all">All</Option>
                        <Option value="Analytics">Analytics</Option>
                        <Option value="Seo">SEO</Option>
                    </Select>
                </Col>
            </Row>
            {state.tickets &&
            <div className={"table-wrapper ant-table"}>
                <table className="table">
                    <thead className={"ant-table-thead"}>
                    <tr>
                        <th scope="col" style={{ color:"black" }}>Id</th>
                        <th scope="col">Title</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Deadline</th>
                    </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                    {state.tickets.map((ticket, index) =>
                        <TicketRow key={index} ticket={ticket} />
                    )}
                    </tbody>
                </table>
                <Pagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }
        </div>
    )
}


export default TicketTable;