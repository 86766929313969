import React from 'react'
import moment from 'moment';

function LogRow({ log }) {
    return (
        <tr className="user-row">
            <td className="">
                {log?.User.name} {log.description} at {moment(log?.createdAt).format("DD/MM/YYYY hh:mm:ss")}
            </td>
        </tr>
    )
}

export default LogRow