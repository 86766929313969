import React, {useContext} from 'react';
import {Layout, Menu} from 'antd';
import {Link, Route, Switch, useHistory} from "react-router-dom";

import Icon, {
    BarsOutlined,
    GlobalOutlined,
    HomeOutlined,
    LogoutOutlined,
    MessageOutlined,
    ShakeOutlined,
    TeamOutlined
} from '@ant-design/icons';

import {RiDashboardLine} from 'react-icons/ri';
import DashboardPage from "./Dashboard/DashboardPage";
import {Context} from "../app/store";
import {USER_LOGOUT} from "../app/user/UserTypes";
import HotelPage from "./Hotels/HotelPage";
import HotelDetail from "./Hotels/HotelDetail";
import UserPage from "./Users/UserPage";
import UserDetail from "./Users/UserDetail";
import SubmitRequestPage from "./SubmitRequests/SubmitRequestPage";
import SubmitRequestDetail from "./SubmitRequests/SubmitRequestDetail";
import {isAdmin} from "../service/RoleService";
import TicketPage from "./Tickets/TicketPage";
import TicketDetail from "./Tickets/TicketDetail";
import CountryPage from "./Countries/CountryPage";
import CategoryPage from "./Categories/CategoryPage";

function BasePage (){

    const { Content, Sider } = Layout;
    const history = useHistory();
    const dispatch = useContext(Context)[1];

    const logout = () => {
        dispatch({type:USER_LOGOUT})
        history.push("/");
    };

    return (
        <div>
            <Layout>
                <Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                >
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} className={"main-sider"}>
                        {isAdmin() &&
                            <Menu.Item key="/" icon={<Icon component={()=>(<RiDashboardLine />)}/>}>
                                Dashboard
                                <Link to="/" />
                            </Menu.Item>
                        }
                        <Menu.Item key="/submitrequests" icon={<MessageOutlined />}>
                            Submit Requests
                            <Link to="/submitrequests" />
                        </Menu.Item>

                        <Menu.Item key="/tickets" icon={<ShakeOutlined />}>
                            Tickets
                            <Link to="/tickets" />
                        </Menu.Item>

                        <Menu.Item key="/hotels" icon={<HomeOutlined />}>
                            Hotels
                            <Link to="/hotels" />
                        </Menu.Item>

                        {isAdmin() &&
                            <Menu.Item key="/users" icon={<TeamOutlined/>}>
                                Users
                                <Link to="/users"/>
                            </Menu.Item>
                        }

                        {isAdmin() &&
                            <Menu.Item key="/categories" icon={<BarsOutlined />}>
                                Categories
                                <Link to="/categories"/>
                            </Menu.Item>
                        }

                        {isAdmin() &&
                            <Menu.Item key="/countries" icon={<GlobalOutlined/>}>
                                Countries
                                <Link to="/countries"/>
                            </Menu.Item>
                        }

                        { isAdmin() &&
                            <div className="role">
                                MT Admin
                            </div>
                        }
                        <Menu.Item key="/logout" className={"logout-button"} icon={<LogoutOutlined />} onClick={()=>logout()}>
                            Logout
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                    {/*<Header className="site-layout-background" style={{ padding: 0 }} />*/}
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }} className="content">
                        <Switch>

                            <Route path="/hotels">
                                <HotelPage />
                            </Route>
                            <Route path="/hotel/:hotelId">
                                <HotelDetail />
                            </Route>

                            {isAdmin() &&
                                <Route path="/users">
                                    <UserPage/>
                                </Route>
                            }
                            {isAdmin() &&
                                <Route path="/user/:userId">
                                    <UserDetail/>
                                </Route>
                            }

                            <Route path="/submitrequests">
                                <SubmitRequestPage />
                            </Route>
                            <Route path="/submitrequest/:submitRequestId">
                                <SubmitRequestDetail />
                            </Route>

                            <Route path="/tickets">
                                <TicketPage />
                            </Route>
                            <Route path="/ticket/:ticketId">
                                <TicketDetail />
                            </Route>

                            {isAdmin() &&
                                <Route path="/categories">
                                    <CategoryPage/>
                                </Route>
                            }

                            {isAdmin() &&
                                <Route path="/countries">
                                    <CountryPage/>
                                </Route>
                            }

                            {isAdmin() ?
                                <Route exact path="/" component={DashboardPage}/> :
                                <SubmitRequestPage />
                            }
                        </Switch>
                    </Content>
                    {/*<Footer style={{ textAlign: 'center' }}>MVP ©2020 Created by Mediatropy</Footer>*/}
                </Layout>
            </Layout>,
        </div>
    );
};

export default BasePage;
