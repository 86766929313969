import React, {useEffect, useState} from 'react';
import clientAPI from "../../service/ApiService";
import {Alert, Input} from 'antd';
import Row from "./CategoryRow";

import Pagination from "../Common/Pagination";
import {getCategoriesEndpoint} from "../../service/RoleService";

function CategoryTable({refresh, update}) {

    const initialState = {
        page: 1,
        categories: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getCategories()
    }, [state.page, state.sizePerPage, refresh]);// eslint-disable-line react-hooks/exhaustive-deps

    const getCategories = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        });

        let requestUrl = getCategoriesEndpoint() +
            "?page=" + state.page +
            "&limit=" + state.sizePerPage +
            (search ? "&keyword=" + encodeURIComponent(search) : "");

        clientAPI.get(requestUrl)
            .then(response => {

                let categories = response.data?.data;
                if(!categories || categories.length <= 0 ){
                    setState({...state,
                        categories:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page
                    });
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    categories: categories,
                    totalSize: response.data.pagination.total
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    categories: [],
                    totalSize: null
                })
            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)
    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getCategories(searchText)
            }, 300)
        )
    }

    return (
        <div>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <Search placeholder="Search category by id or name"
                    loading={state.loading}
                    onChange={(e) => setSearch(e)}
            />
            {state.categories &&
            <div className={"table-wrapper ant-table"}>
                <table className="table">
                    <thead className={"ant-table-thead"}>
                    <tr>
                        <th scope="col" style={{ color:"black" }}>Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                    {state.categories.map((category, index) =>
                        <Row key={index} category={category} update={update} />
                    )}
                    </tbody>
                </table>
                <Pagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }
        </div>
    )
}


export default CategoryTable;