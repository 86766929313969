import React, {useState} from 'react';
import {message, Select} from "antd";
import clientAPI from "../../service/ApiService";
import {API_ADMIN_TICKET} from "../../constants";

function TicketDropdown({ticket, setStatus}) {

    const { Option } = Select;
    const [loading, setLoading] = useState(false);

    const changeStatus = (status) => {

        setLoading(true);
        clientAPI.put(API_ADMIN_TICKET + "/status/" + ticket.id, {"status":status})
            .then(response => {
                let ticket = response?.data?.data;
                if(!ticket){
                    return false
                }
                setLoading(false);
                setStatus(status);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    }

    return (
        <Select
            showSearch={true}
            placeholder={"Change Status"}
            onChange={(status)=>changeStatus(status)}
            loading={loading}
            value={ticket.status}
            className={"ticket-status-dropdown"}
        >
            <Option value={"submitted"}>Submitted</Option>
            <Option value={"pending_review"}>Pending Review</Option>
            <Option value={"ongoing"}>On-going</Option>
            <Option value={"qa"}>Q&A</Option>
            <Option value={"revision"}>Revision</Option>
            <Option value={"completed"}>Completed</Option>
        </Select>
    );
}

export default TicketDropdown;
