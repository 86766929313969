import React, {useEffect, useState} from 'react';
import {Button, Col, Descriptions, message, Row, Spin} from 'antd';
import clientAPI from "../../service/ApiService";
import {API_HOTELS} from "../../constants";
import {Link, useHistory, useParams} from 'react-router-dom';
import HotelModal from "./HotelModal";
import {getHotelsEndpoint, isAdmin} from "../../service/RoleService";

function HotelDetail() {

    const history = useHistory();
    const [hotelModal, setHotelModal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [hotel, setTemplate] = useState(null)
    const {hotelId} = useParams();

    useEffect(() => {
        if(!hotelId)
            return;
        getHotel()
    }, [hotelId]);// eslint-disable-line react-hooks/exhaustive-deps

    const getHotel = () => {
        setLoading(true);

        clientAPI.get(getHotelsEndpoint() + "/" + hotelId)
            .then(response => {
                let hotel = response?.data?.data;
                if(!hotel){
                    return false
                }

                setTemplate(hotel);
                setLoading(false);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    };

    const deleteHotel = () => {
        setLoading(true);

        clientAPI.delete(API_HOTELS + "/" + hotelId)
            .then(response => {
                setLoading(false);
                history.push("/hotels");
            })
            .catch(error => {
                setLoading(false);
                let status = error?.response?.data?.statusCode?.code;
                if(status === -6002){
                    message.error('You are trying to delete a hotel that has some information attached.');
                }else{
                    message.error('Error deleting the hotel');
                }
            })
    };

    const doRefresh = () => {
        getHotel();
    }

    return (
        <div className={"detail-page"}>
            <Link to={"/hotels/"}>
                Back to Hotels
            </Link>
            {loading && <Spin/>}
            {hotel &&
                <React.Fragment>
                    <Row>
                        <Col span={8}>
                            <h2>Hotel Detail</h2>
                        </Col>
                        { isAdmin() &&
                            <Col span={8} offset={8} className={"actions"}>
                                <Button onClick={() => setHotelModal(hotelModal+1)}>Update</Button>
                                <Button danger onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteHotel() } }>Delete</Button>
                            </Col>
                        }
                    </Row>

                    <Descriptions bordered>
                        <Descriptions.Item label="Id" span={1}>{hotel.id}</Descriptions.Item>
                        <Descriptions.Item label="Title" span={2}>{hotel.name}</Descriptions.Item>
                        <Descriptions.Item label="Country" span={2}>{hotel.Country?.name}</Descriptions.Item>
                    </Descriptions>
                </React.Fragment>
            }
            {isAdmin() &&
                <HotelModal
                    hotel={hotel}
                    open={hotelModal}
                    doRefresh={doRefresh}
                />
            }
        </div>
    );
}

export default HotelDetail;
