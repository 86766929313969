import React, {useEffect, useState} from 'react';
import {API_USER_USPS} from "../../../constants";
import {Button, Card, message, Space} from "antd";
import clientAPI from "../../../service/ApiService";
import USPModal from "./USPModal";
import {getUSPEndpoint, isAdmin} from "../../../service/RoleService";
import CommentsDrawer from "./Comments/CommentsDrawer";
import moment from 'moment';

function SubmitRequestTable({submitRequest}) {

    const [loading, setLoading] = useState(false);
    const [commentDrawer, setCommentDrawer] = useState({visible: false, usp:null});
    const [usps, setUsps] = useState([]);
    const [state, setState] = useState({uspModal:0, usp:null});

    useEffect(() => {
        getUSPS()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getUSPS = () => {
        setLoading(true);

        let requestEndpoint = "";
        if(isAdmin()){
            requestEndpoint = getUSPEndpoint() + "/bysubmitrequest/" + submitRequest.id;
        }else{
            requestEndpoint = getUSPEndpoint() + "/" + submitRequest.id;
        }

        clientAPI.get(requestEndpoint)
            .then(response => {
                setLoading(false);
                let data = response.data?.data;
                if(!data || data.length <= 0 ){
                    setUsps([]);
                    return false
                }
                setUsps(data);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    }

    const refresh = () => {
        getUSPS();
    }

    // const editUsp = (usp) => {
    //     console.log("editing usp ", usp)
    // }

    const setCommentDrawerVisible = (visible, usp) => {
        setCommentDrawer({...state, visible: visible, usp: usp});
    }

    const deleteUSP = (usp) => {
        clientAPI.delete(API_USER_USPS + "/" + usp.id)
            .then(response => {
                setLoading(false);
                getUSPS();
            })
            .catch(error => {
                message.error(error?.response?.data?.statusCode?.msg);
                setLoading(false);
            })
    }

    return (
        <div className={"usps-container"}>
            {!isAdmin() &&
                <Button
                    type={"primary"}
                    loading={loading}
                    onClick={() => setState({...state, usp: null, uspModal: state.uspModal+1})} >
                    Create USP
                </Button>
            }
            <div className={"usps-grid"}>
                {usps?.map((usp, index) =>
                    <div className={"usp"}>
                        <Card key={index}
                              size="small"
                              title={<a href={usp.landingUrl} rel={"noreferrer"} target={"_blank"}>{usp.landingUrl}</a>}
                              extra={moment(usp.createdAt).format("DD-MM-YYYY")}
                        >
                            <p>{usp.description}</p>
                            <Space>
                                <Button
                                    type={"secondary"}
                                    onClick={() => setCommentDrawerVisible(true, usp)}
                                >
                                    Comments ({usp.Comments?.length})
                                </Button>
                                {!isAdmin() &&
                                <Button type={"primary"}
                                        onClick={() => setState({...state, usp: usp, uspModal: state.uspModal+1})}
                                >
                                    Edit
                                </Button>
                                }
                                { !isAdmin() &&
                                <Button type="link" danger onClick={()=> { if (window.confirm('Are you sure you wish to delete this item?')) deleteUSP(usp) } }>
                                    Delete
                                </Button>
                                }

                            </Space>
                        </Card>
                    </div>
                )}
            </div>
            <USPModal
                submitRequest={submitRequest}
                open={state.uspModal}
                usp={state.usp}
                doRefresh={() => refresh()}
            />
            <CommentsDrawer
                visible={commentDrawer.visible}
                setVisible={setCommentDrawerVisible}
                usp={commentDrawer.usp}
                refresh={refresh}
            />
        </div>
    )
}


export default SubmitRequestTable;