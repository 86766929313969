import React, {useContext, useState} from 'react'
import {Alert, Button, Form, Input, message} from 'antd';
import {useHistory} from "react-router-dom";
import {API_URL} from "../../../constants";
import axios from 'axios';
import {Context} from "../../../app/store";
import {USER_LOGIN} from "../../../app/user/UserTypes";

function RegisterPage() {

    const dispatch = useContext(Context)[1];

    const initialState = {
        error: null,
        loading: false,
    };
    const [state, setState] = useState(initialState);
    const [token, setToken] = useState(null);
    const history = useHistory();

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        setToken(params.get('TOKEN'));
    }, [])


    const onFinish = (values) => {
        setState({...state,loading: true});

        axios.post(API_URL + "/register/" + token, values)
            .then(response => {
                const token = response?.data?.data?.token;
                if(token && token.length > 0){
                    setState({
                        ...state,
                        error: null,
                        loading: false,
                    });
                    dispatch({type: USER_LOGIN, payload: {
                        "token":token,
                        "role":response?.data?.data?.role,
                        "userId":response?.data?.data?.userId
                    }});
                    history.push("/");
                }else{
                    setState({
                        ...state,
                        error: "Error connecting with the server, try again later",
                        loading: false,
                    });
                }
            })
            .catch(error =>{
                setState({
                    ...state,
                    loading: false,
                });
                if(error?.response?.data?.statusCode?.code === -19){
                    message.error("User not found or incorrect password");
                    return
                }
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg)
            })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(errorInfo);
    };

    return (
        <div className={"register-page"}>
            <div className="">
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                <h3>Register</h3>
                <p>Please setup a password for your account</p>
                {token &&
                    <Form
                        name="basic"
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Repeat Password"
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit" loading={state.loading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                }
                {!token &&
                    <p>Invalid token</p>
                }
            </div>
        </div>
    )
}

export default RegisterPage
