import React from 'react'
import {Link} from "react-router-dom";

function Row({ hotel }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <Link to={"/hotel/" + hotel?.id}>
                    <p style={{ color:"#1e88e5" }}>{hotel.id}</p>
                </Link>
            </td>
            <td className="">
                <Link to={"/hotel/" + hotel?.id}>
                    {hotel.name}
                </Link>
            </td>
            <td className="">
                {hotel.Country?.name}
            </td>
            <td className="hotel-brand">
                {hotel.brand}
            </td>
        </tr>
    )
}

export default Row