import React from 'react'
import {Link} from "react-router-dom";
import {Tag} from 'antd';

function Row({ user }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <Link to={"/user/" + user?.id}>
                    <p style={{ color:"#1e88e5" }}>{user.id}</p>
                </Link>
            </td>
            <td className="">
                <Link to={"/user/" + user?.id}>
                    {user.name}
                </Link>
            </td>
            <td className="">
                {user.email}
            </td>
            <td className="">
                { user.activated ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag> }
            </td>
            <td className="" style={{textTransform: 'capitalize'}}>
                {user.role}
            </td>
        </tr>
    )
}

export default Row