import React, {useEffect, useState} from 'react';
import {Timeline} from "antd";
import clientAPI from "../../service/ApiService";
import moment from 'moment';
import {getAuditEndpoint} from "../../service/RoleService";


function TicketTimeline({ticket, refresh}) {

    const initialState = {
        page: 1,
        logs: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getUsps()
    }, [state.page, state.sizePerPage, refresh]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUsps = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        });

        let requestUrl = getAuditEndpoint() +
            "?page=" + state.page +
            "&limit=" + state.sizePerPage +
            "&ticketId=" + ticket.id +
            "&order=asc" +
            (search ? "&keyword=" + encodeURIComponent(search) : "");

        clientAPI.get(requestUrl)
            .then(response => {

                let logs = response.data?.data;
                if(!logs || logs.length <= 0 ){
                    setState({...state,
                        logs:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page
                    });
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    logs: logs,
                    totalSize: response.data.pagination.total
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    logs: [],
                    totalSize: null
                })
            })
    }

    return (
        <div>
            <Timeline>
                {state.logs.map((log, index) =>
                    <Timeline.Item key={index}>{log?.User.name} {log.description} at {moment(log?.createdAt).format("DD/MM/YYYY hh:mm:ss")}</Timeline.Item>
                )}
            </Timeline>
        </div>
    );
}

export default TicketTimeline;
