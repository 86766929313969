/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, message, Modal, Select} from "antd";
import clientAPI from "../../service/ApiService";
import {API_HOTELS, API_USERS} from "../../constants";

function AddUserHotelModal({open, doRefresh, selectedHotels, user}){

    const [loading, setLoading] = useState(false);
    const [hotelsLoading, setHotelsLoading] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [form] = Form.useForm();

    const { Option } = Select;

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        getHotels();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        setLoading(true);
        clientAPI.post(API_USERS + "/" + user.id + "/hotels", data)
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    const getHotels = (search=null) => {
        setHotelsLoading(true);

        clientAPI.get(API_HOTELS + "?page=1&limit=1000")
            .then(response => {

                let hotels = response.data?.data;
                if(!hotels || hotels.length <= 0 ){
                    setHotels([])
                    return false
                }
                setHotels(hotels);
                setHotelsLoading(false);
            })
            .catch(error => {
                message.error("Error loading the hotels");
                setLoading(false);
            })
    }

    return (
        <Modal
            title="Add hotel to a user"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["hotels"]: selectedHotels.map(x=>x.id),
                }}
            >
                <Form.Item
                    label="Hotels"
                    name="hotels"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        placeholder="Select hotels"
                        loading={hotelsLoading}
                        mode="multiple"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {hotels?.map((hotel, index) =>
                            <Option key={index} value={hotel.id}>{hotel.name}</Option>
                        )}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default AddUserHotelModal;
