import React, {useState} from 'react';
import TicketTable from './TicketTable'
import {Button} from "antd";
import TicketModal from "./TicketModal";
import {isAdmin} from "../../service/RoleService";

function TicketPage(){

    const [ticketModal, setTicketModal] = useState(0);
    const [refresh, setRefresh] = useState(0)

    const doRefresh = () => {
        setRefresh(refresh+1);
    }

    return (
        <div>
            <h2>Tickets</h2>
            { !isAdmin() &&
                <Button className={"create-cta"} type={"primary"} onClick={() => setTicketModal(ticketModal+1)} >Create</Button>
            }
            <TicketTable refresh={refresh} />
            <TicketModal
                ticket={null}
                open={ticketModal}
                doRefresh={doRefresh}
                titleModal={"Create Ticket"}
            />

        </div>
    )
};

export default TicketPage;
