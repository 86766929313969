/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Select} from "antd";
import clientAPI from "../../service/ApiService";
import {getHotelsEndpoint, getUserInviteEndpoint, isAdmin} from "../../service/RoleService";

function UserModal({open, doRefresh}){

    const [loading, setLoading] = useState(false);
    const [hotelsLoading, setHotelsLoading] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [form] = Form.useForm();

    const { Option } = Select;

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        getHotels();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        setLoading(true);
        let reqEnd = "";
        if (data.role === "admin") {
            reqEnd = "/invite-admin";
        } else if (data.role === "manager") {
            reqEnd ="/invite-manager";
        } else {
            reqEnd = "/invite";
        }
        delete data.role;

        clientAPI.post(getUserInviteEndpoint() + reqEnd, data)
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    const getHotels = (search=null) => {
        setHotelsLoading(true);

        clientAPI.get(getHotelsEndpoint()
            + "?limit=10000"
            + "&order=asc"
        )
            .then(response => {

                let hotels = response.data?.data;
                if(!hotels || hotels.length <= 0 ){
                    setHotels([])
                    return false
                }
                setHotels(hotels);
                setHotelsLoading(false);
            })
            .catch(error => {
                message.error("Error loading the hotels");
                setLoading(false);
            })
    }

    return (
        <Modal
            title="Create user"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input
                        autoFocus={true}
                    />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        placeholder="Role"
                    >
                        { isAdmin() &&
                            <>
                                <Option value={"admin"}>Admin</Option>
                                <Option value={"manager"}>Manager</Option>
                            </>
                        }
                        <Option value={"user"}>User</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Hotels"
                    name="hotels"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch={true}
                        placeholder="Select hotels"
                        loading={hotelsLoading}
                        mode="multiple"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {hotels?.map((hotel, index) =>
                            <Option key={index} value={hotel.id}>{hotel.name}</Option>
                        )}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default UserModal;
