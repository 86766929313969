import React from 'react'
import {Button, Form, Input, message} from 'antd';
import {useHistory} from "react-router-dom";
import {API_URL} from "../../../constants";
import axios from 'axios';

function ForgotPage() {
    const history = useHistory();

    const onFinish = (email) => {
        axios.post(API_URL + "/forgot", email)
            .then(response => {
                history.push("/login");
            })
            .catch(error =>{
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg)
            })
    };

    return (
        <div className={"login-page"}>
            <div style={{width: '300px'}}>
                <h3 style={{marginBottom: '24px'}}>Forgot Password</h3>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        labelAlign="left"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPage
