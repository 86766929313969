import React, {useState} from 'react';
import UserTable from './UserTable'
import {Button} from "antd";
import InviteModal from "./InviteModal";

function UserPage(){

    const [inviteModal, setInviteModal] = useState(0);
    const [refresh, setRefresh] = useState(0)

    const doRefresh = () => {
        setRefresh(refresh+1);
    }

    return (
        <div>
            <h2>Users</h2>
            <Button className={"create-cta"}
                    type={"primary"}
                    onClick={() => setInviteModal(inviteModal+1)} >
                Invite user
            </Button>
            <UserTable refresh={refresh}/>
            <InviteModal
                hotel={null}
                open={inviteModal}
                doRefresh={doRefresh}
            />
        </div>
    )
};

export default UserPage;
