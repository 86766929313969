import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Descriptions, Divider, List, message, Row, Space, Spin} from 'antd';
import clientAPI from "../../service/ApiService";
import {Link, useHistory, useParams} from 'react-router-dom';
import TicketModal from "./TicketModal";
import {getTicketsEndpoint, isAdmin, isManager} from "../../service/RoleService";
import moment from 'moment';
import AssignModal from "./AssignModal";
import TicketComments from "./Comments/TicketComments";
import TicketDropdown from "./TicketDropdown";
import TicketStatus from "./TicketStatus";
import TicketTimeline from "./TicketTimeline";
import {API_USER_TICKETS} from "../../constants";
import axios from "axios";


function TicketDetail() {

    const history = useHistory();
    const [ticketModal, setTicketModal] = useState(0);
    const [assignModal, setAssignModal] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false)
    const [ticket, setTicket] = useState(null)
    const {ticketId} = useParams();
    const [imageUrl, setImageUrl] = useState(null);
    const onFileInput = async (e) => {
        const timestamp = new Date().getTime();
        const file = e.target.files[0];
        if(!file){
            return;
        }
        const filename = file.name.split('.')[0].replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase() + `_${timestamp}`;
        const fileExtension = file.name.split('.').pop();

        await uploadImage(`${filename}.${fileExtension}`, file);
    }
    const uploadImage = async (filename, file) => {
        const options = { headers: { 'Content-Type': file.type } };

        try {
            const s3Urls = await axios.get(
                `${process.env.REACT_APP_API_URL}/ticket-files?filename=${filename}&contentType=${file.type}`
            ).then(response => response.data?.urls);

            if (!s3Urls.signedUrl) {
                console.log('signed url is not defined')
                throw new Error('S3 signed url is not defined');
            }

            await axios.put(s3Urls.signedUrl, file, options);

            setImageUrl(s3Urls.publicUrl);
        } catch (err) {
            console.error(`Error uploading image: ${err.message}`);
        }
    }
    useEffect(() => {
        if(!ticketId)
            return;
        getTicket()
    }, [ticketId]);// eslint-disable-line react-hooks/exhaustive-deps

    const getTicket = () => {
        setLoading(true);

        clientAPI.get(getTicketsEndpoint() + "/" + ticketId)
            .then(response => {
                let ticket = response?.data?.data;
                if(!ticket){
                    return false
                }
                
                setTicket(ticket);
                setLoading(false);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    };

    const deleteTicket = () => {
        setLoading(true);

        clientAPI.delete(getTicketsEndpoint() + "/" + ticketId)
            .then(response => {
                setLoading(false);
                history.push("/tickets");
            })
            .catch(error => {
                setLoading(false);
                let status = error?.response?.data?.statusCode?.code;
                if(status === -6002){
                    message.error('You are trying to delete a ticket that has some information attached.');
                }else{
                    message.error('Error deleting the ticket');
                }
            })
    };

    const doRefresh = () => {
        getTicket();
        setRefresh(refresh+1);
    }

    const setStatus = (status) => {
        // setTicket({...ticket, status: status})
        setRefresh(refresh+1);
        getTicket();
    }

    const managerClick = (status) => {
        setLoading(true);

        clientAPI.put(API_USER_TICKETS + "/status/" + ticket.id, {"status":status})
            .then(response => {
                let ticket = response?.data?.data;
                if(!ticket){
                    return false
                }
                setLoading(false);
                setStatus(status);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    }

    return (
        <div className={"detail-page"}>
            <Link to={"/tickets/"}>
                Back to Tickets
            </Link>
            {loading && <Spin/>}
            {ticket &&
                <React.Fragment>
                    <Row>
                        <Col span={8}>
                            <h2>Ticket Detail</h2>
                        </Col>
                        <Col span={8} offset={8} className={"actions"}>
                            <Button onClick={() => setTicketModal(ticketModal+1)}>Update</Button>
                            <Button danger onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteTicket() } }>Delete</Button>
                        </Col>
                    </Row>

                    <Descriptions bordered>
                        <Descriptions.Item label="Id" span={1}>{ticket.id}</Descriptions.Item>
                        <Descriptions.Item label="Hotel" span={2}>
                            {ticket.Hotel?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Deadline" span={1}>
                            {moment(ticket.deadline).format('YYYY-MM-DD')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created By" span={2}>{ticket.CreatedBy?.name}</Descriptions.Item>
                        <Descriptions.Item label="Status" span={1}>
                            {isAdmin() &&
                                <Col span={6}>
                                    <TicketDropdown ticket={ticket} setStatus={setStatus} />
                                </Col>
                            }
                            {!isAdmin() &&
                                <TicketStatus status={ticket.status} />
                            }
                            {isManager() && ticket.status === 'pending_review' &&
                                <Space size={"large"} style={{marginTop: "10px"}}>
                                    <Button type={"primary"} onClick={() => managerClick("approved")}>Approved</Button>
                                    {/*<Button danger onClick={() => managerClick("disapproved")}>Disapproved</Button>*/}
                                </Space>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Category" span={2}>
                            {ticket.TicketType?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Assigned to" span={1}>
                            {isAdmin() &&
                                <Button onClick={()=>setAssignModal(assignModal+1)}>Assign user</Button>
                            }
                            <List
                                itemLayout="horizontal"
                                dataSource={ticket.Users}
                                renderItem={user => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={ user?.name }
                                            description={user?.email}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Log" span={2}>
                            <TicketTimeline ticket={ticket} refresh={refresh} />
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="ticket-desc">
                        <h2>{ticket.title}</h2>
                        <p>{ticket.description}</p>
                    </div>
                    {ticket.attachment &&
                        <Fragment>
                            <Divider plain>Attachment</Divider>
                            <a target={"_blank"} href={ticket.attachment}>Attached File</a>
                        </Fragment>
                    }

                    <Divider plain>Comments</Divider>
                    <TicketComments ticket={ticket} />

                </React.Fragment>
            }
            <TicketModal
                ticket={ticket}
                open={ticketModal}
                doRefresh={doRefresh}
                titleModal={"Update Ticket"}
            />

            <AssignModal
                ticket={ticket}
                open={assignModal}
                doRefresh={doRefresh}
            />
        </div>
    );
}

export default TicketDetail;
