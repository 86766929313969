import React, {useEffect, useState} from 'react';
import {Button, Col, Descriptions, Divider, message, Row, Spin} from 'antd';
import clientAPI from "../../service/ApiService";
import {API_SUBMITS, API_USER_SUBMITS} from "../../constants";
import {Link, useHistory, useParams} from 'react-router-dom';
import moment from 'moment';
import SubmitRequestUsersTable from "./SubmitRequestUsersTable";
import {isAdmin} from "../../service/RoleService";
import USPTable from "./USP/USPTable";

function SubmitRequestDetail() {

    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [submitRequest, setSubmitRequest] = useState(null)
    const [showUsers, setShowUsers] = useState(false)
    const {submitRequestId} = useParams();

    useEffect(() => {
        if(!submitRequestId)
            return;
        getSubmitRequest();
    }, [submitRequestId]);// eslint-disable-line react-hooks/exhaustive-deps

    const getSubmitRequest = () => {
        setLoading(true);

        clientAPI.get(API_USER_SUBMITS + "/" + submitRequestId)
            .then(response => {
                let req = response?.data?.data;
                if(!req){
                    return false
                }
                setSubmitRequest(req);
                setLoading(false);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    };

    const deleteSubmitRequest = () => {
        setLoading(true);

        clientAPI.delete(API_SUBMITS + "/" + submitRequestId)
            .then(response => {
                setLoading(false);
                history.push("/submitRequests");
            })
            .catch(error => {
                setLoading(false);
                let status = error?.response?.data?.statusCode?.code;
                if(status === -6002){
                    message.error('You are trying to delete a submitRequest that has some information attached.');
                }else{
                    message.error('Error deleting the submitRequest');
                }
            })
    };

    return (
        <div className={"detail-page"}>
            <Link to={"/submitRequests/"}>
                Back to Submit Requests
            </Link>
            {loading && <Spin/>}
            {submitRequest &&
                <React.Fragment>
                    <Row>
                        <Col span={8}>
                            <h2>Submit request detail</h2>
                        </Col>
                        <Col span={8} offset={8} className={"actions"}>
                            <Button onClick={() => {setShowUsers(!showUsers)} }>
                                {showUsers ? "Hide Users" : "Show Users"}
                            </Button>
                            { isAdmin() &&
                                <Button danger onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteSubmitRequest() } }>Delete</Button>
                            }
                        </Col>
                    </Row>

                    <Descriptions bordered> 
                        <Descriptions.Item label="Id" span={3}>{submitRequest.id}</Descriptions.Item>
                        <Descriptions.Item label="Property name" span={1}>{submitRequest.Hotel?.name}</Descriptions.Item>
                        <Descriptions.Item label="Country" span={2}>{submitRequest.Hotel?.Country?.name}</Descriptions.Item>
                        <Descriptions.Item label="Created at" span={1}>{moment(submitRequest.createdAt).format("DD-MM-YYYY")}</Descriptions.Item>
                        <Descriptions.Item label="Updated at" span={2}>{moment(submitRequest.udpatedAt).format("DD-MM-YYYY")}</Descriptions.Item>
                        <Descriptions.Item label="Content" span={2}>{submitRequest.content}</Descriptions.Item>
                    </Descriptions>
                    {showUsers &&
                        <SubmitRequestUsersTable submitRequest={submitRequest} />
                    }
                    <Divider plain>USPs</Divider>
                    <USPTable submitRequest={submitRequest} />
                </React.Fragment>
            }
        </div>
    );
}

export default SubmitRequestDetail;
