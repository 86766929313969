import React, {useEffect, useState} from 'react';
import {Button, Comment, List, message} from "antd";
import Editor from "./Editor";
import clientAPI from "../../../service/ApiService";
import {getTicketCommentEndpoint} from "../../../service/RoleService";
import moment from "moment";

function TicketComments({ticket}){

    const [comments, setComments] = useState([]);
    const [loadingComments, setLoadingComments] = useState(false);
    const [state, setState] = useState({
        loading: false,
        value: '',
    });

    useEffect(() => {
        if(!ticket)
            return;
        getComments();
    }, [ticket]);// eslint-disable-line react-hooks/exhaustive-deps

    const getComments = () => {
        setState({...state,
            loading: false,
            value: '',
        });
        setLoadingComments(true);
        clientAPI.get(getTicketCommentEndpoint() + "?ticketId=" + ticket?.id + "&limit=1000&order=asc")
            .then(response => {
                setLoadingComments(false);
                let data = response.data?.data;
                if(!data || data.length <= 0 ){
                    setComments([]);
                    return false
                }

                setComments(data.map( (c) => {
                        return {
                            author: c?.User?.email,
                            content: (
                                <div dangerouslySetInnerHTML={{__html: c.body}} />
                            ),
                            datetime: (
                                <span>{moment(c.createdAt).format("DD MM YYYY hh:mm:ss")}</span>
                            ),
                            action: [(<Button danger type={"link"} className={"delete-comment"}
                                                                       // onClick={()=>deleteComment(c)}
                            >
                                Delete</Button>)],
                        }
                    }
                ))
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoadingComments(false);
            })
    }

    const handleSubmit = () => {
        if (!state.value) {
            return;
        }

        setState({
            ...state,
            loading: true,
        });

        let data = {
            ticketId : ticket.id,
            body : state.value
        }

        clientAPI.post(getTicketCommentEndpoint() + "?ticketId=" + ticket?.id, data)
        .then(() => {
            setState({...state,
                loading: false,
                value: '',
            });
            getComments();
        })
        .catch(error => {
            let msg = error?.response?.data?.statusCode?.msg
            message.error(msg);
            setState({...state,
                loading: false,
                value: '',
            });
        })

    };

    const handleChange = e => {
        setState({ ...state,
            value: e,
        });
    };

    return (
        <div className={"ticket-comments"}>
            <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={comments}
                loading={loadingComments}
                renderItem={item => (
                    <li>
                        <Comment
                            // actions={item.action}
                            author={item.author}
                            content={item.content}
                            datetime={item.datetime}
                        />
                    </li>
                )}
            />
            <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={state.loading}
                value={state.value}
            />
        </div>
    )
};

export default TicketComments;
