export const API_URL = process.env.REACT_APP_API_URL;

export const API_SUBMITS          = API_URL + "/admin/submitrequest";
export const API_HOTELS           = API_URL + "/admin/hotels";
export const API_USERS            = API_URL + "/admin/users";
export const API_ADMIN_USP        = API_URL + "/admin/usp";
export const API_ADMIN_COMMENT    = API_URL + "/admin/comment";
export const API_ADMIN_TICKET     = API_URL + "/admin/ticket";
export const API_ADMIN_TICKET_COMMENT  = API_URL + "/admin/ticketcomment";
export const API_COUNTRIES        = API_URL + "/countries";
export const API_ADMIN_CATEGORIES = API_URL + "/admin/tickettype";
export const API_ADMIN_AUDIT      = API_URL + "/admin/audit";

export const API_USER_USERS       = API_URL + "/user";
export const API_USER_HOTELS      = API_URL + "/hotels";
export const API_USER_SUBMITS     = API_URL + "/submitrequest";
export const API_USER_USPS        = API_URL + "/usp";
export const API_USER_COMMENTS    = API_URL + "/comment";
export const API_USER_TICKET_COMMENTS = API_URL + "/ticketcomments";
export const API_USER_TICKETS     = API_URL + "/tickets";
export const API_USER_CATEGORIES  = API_URL + "/tickettypes";
export const API_USER_AUDIT      = API_URL + "/audit";