import React, {useEffect, useState} from 'react';
import {Alert, Input} from 'antd';
import clientAPI from "../../service/ApiService";
import Pagination from "../Common/Pagination";
import {API_ADMIN_AUDIT} from "../../constants";
import LogRow from "./LogRow";

function LogTable(){

    const initialState = {
        page: 1,
        logs: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getUsps()
    }, [state.page, state.sizePerPage]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUsps = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        });

        let requestUrl = API_ADMIN_AUDIT +
            "?page=" + state.page +
            "&limit=" + state.sizePerPage +
            "&order=desc" +
            (search ? "&keyword=" + encodeURIComponent(search) : "");

        clientAPI.get(requestUrl)
            .then(response => {

                let logs = response.data?.data;
                if(!logs || logs.length <= 0 ){
                    setState({...state,
                        logs:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page
                    });
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    logs: logs,
                    totalSize: response.data.pagination.total
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    logs: [],
                    totalSize: null
                })
            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null);

    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getUsps(searchText)
            }, 300)
        )
    }

    return (
        <div>
            <p>System logs</p>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <Search placeholder="Search submit request by id or name"
                    loading={state.loading}
                    onChange={(e) => setSearch(e)}
            />
            {state.logs &&
            <div className={"table-wrapper ant-table"}>
                <table className="table">
                    <thead className={"ant-table-thead"}>
                    <tr>
                        <th scope="col">Description</th>
                    </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                    {state.logs.map((log, index) =>
                        <LogRow key={index} log={log} />
                    )}
                    </tbody>
                </table>
                <Pagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }
        </div>
    );
};

export default LogTable;
