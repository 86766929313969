import React, {useState} from 'react';
import CategoryTable from './CategoryTable'
import {Button} from "antd";
import CategoryModal from "./CategoryModal";
import {isAdmin} from "../../service/RoleService";

function CategoryPage(){

    const [categoryModal, setCategoryModal] = useState({modal:0, category:null});
    const [refresh, setRefresh] = useState(0)

    const doRefresh = () => {
        setRefresh(refresh+1);
    }

    const update = (category) => {
        setCategoryModal({modal:categoryModal.modal+1, category: category})
    }

    return (
        <div>
            <h2>Categories</h2>
            { isAdmin() &&
                <Button className={"create-cta"}
                        type={"primary"}
                        onClick={() => setCategoryModal({modal:categoryModal.modal+1, category: null})} >
                    Create
                </Button>
            }
            <CategoryTable refresh={refresh} update={update} />
            <CategoryModal
                category={categoryModal.category}
                open={categoryModal.modal}
                doRefresh={doRefresh}
            />

        </div>
    )
};

export default CategoryPage;
