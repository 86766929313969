/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Select, Switch} from "antd";
import clientAPI from "../../service/ApiService";
import {API_USERS} from "../../constants";

function UserModal({open, user, doRefresh}){

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const { Option } = Select;

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        setLoading(true);
        data = {...data, activated : data.activated ?? false}

        clientAPI.put(API_USERS + "/" + user.id, data)
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    return (
        <Modal
            title="Create user"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["name"]: user?.name,
                    ["email"]: user?.email,
                    ["activated"]: user?.activated,
                    ["role"]: user?.role,
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input
                        autoFocus={true}
                    />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email' }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Enabled"
                    name="activated"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        placeholder="Role"
                    >
                        <Option value={"admin"}>Admin</Option>
                        <Option value={"user"}>User</Option>
                    </Select>
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default UserModal;
