/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal} from "antd";
import clientAPI from "../../service/ApiService";
import {getCategoriesEndpoint} from "../../service/RoleService";

function CategoryModal({open, category, doRefresh}){

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if(open <= 0)
            return;
        showModal();
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {

        setLoading(true);
        let request = {};
        if(category === null){
            request = clientAPI.post(getCategoriesEndpoint(), data);
        }else{
            request = clientAPI.put(getCategoriesEndpoint() + "/" + category.id, data);
        }
        request
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    return (
        <Modal
            title="Create category"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["name"]: category?.name,
                }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input
                        autoFocus={true}
                    />
                </Form.Item>

            </Form>
        </Modal>
    )
};

export default CategoryModal;
