import React from 'react'
import {Link} from "react-router-dom";
import moment from "moment";
import TicketStatus from "./TicketStatus";

function Row({ ticket }) {
    return (
        <tr className="user-row">
            <td className="user-id">
                <Link to={"/ticket/" + ticket?.id}>
                    <p style={{ color:"#1e88e5" }}>{ticket?.id}</p>
                </Link>
            </td>
            <td className="">
                <Link to={"/ticket/" + ticket?.id}>
                    {ticket?.title}
                </Link>
            </td>
            <td className="ticket-status">
                <TicketStatus status={ticket?.status} />
            </td>
            <td className="ticket-status">
                {ticket?.TicketType?.type}
            </td>
            <td className="">
                {moment(ticket?.deadline).format('YYYY-MM-DD HH:mm:ss')}
            </td>
        </tr>
    )
}

export default Row