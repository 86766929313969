import React from 'react'
import {Link} from "react-router-dom";
import moment from 'moment';

function Row({ submitRequest }) {
    return (
        <tr key={submitRequest?.id} className="user-row">
            <td className="user-id">
                <Link to={"/submitRequest/" + submitRequest?.id}>
                    <p style={{ color:"#1e88e5" }}>{submitRequest?.id}</p>
                </Link>
            </td>
            <td className="">
                <Link to={"/submitRequest/" + submitRequest?.id}>
                    {submitRequest?.Hotel?.name}
                </Link>
            </td>
            <td className="">
                {moment(submitRequest?.createdAt).format("DD-MM-YYYY")}
            </td>
        </tr>
    )
}

export default Row