import Cookies from 'universal-cookie';
import {
    API_HOTELS,
    API_USER_HOTELS,
    API_USERS,
    API_USER_USERS,
    API_SUBMITS,
    API_USER_SUBMITS,
    API_USER_USPS,
    API_ADMIN_USP,
    API_ADMIN_COMMENT,
    API_USER_COMMENTS,
    API_ADMIN_TICKET,
    API_USER_TICKETS,
    API_ADMIN_TICKET_COMMENT,
    API_USER_TICKET_COMMENTS,
    API_ADMIN_CATEGORIES,
    API_USER_CATEGORIES,
    API_USER_AUDIT,
    API_ADMIN_AUDIT
} from "../constants";

export function isAdmin() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin";
}

export function isManager() {
    const cookies = new Cookies();
    return cookies.get('role') === "manager";
}

export function getUserId(){
    const cookies = new Cookies();
    return cookies.get('userId');
}

export function getHotelsEndpoint() {
    const cookies = new Cookies();
    var role = cookies.get('role');
    return role === "admin" ? API_HOTELS : API_USER_HOTELS;
}

export function getAllUsersEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_USERS : API_USER_USERS+"/all";
}

export function getDetailUsersEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_USERS : API_USER_USERS;
}

export function getUserInviteEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_USERS : API_USER_USERS;
}

export function getSubmitRequestsEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_SUBMITS : API_USER_SUBMITS;
}

export function getUSPEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_USP : API_USER_USPS;
}

export function getCommentEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_COMMENT : API_USER_COMMENTS;
}

export function getTicketsEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_TICKET : API_USER_TICKETS;
}

export function getTicketCommentEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_TICKET_COMMENT : API_USER_TICKET_COMMENTS;
}

export function getCategoriesEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_CATEGORIES : API_USER_CATEGORIES;
}

export function getAuditEndpoint() {
    const cookies = new Cookies();
    return cookies.get('role') === "admin" ? API_ADMIN_AUDIT : API_USER_AUDIT;
}