import React, {useContext, useState} from 'react'
import {Alert, Button, Form, Input, message} from 'antd';
import {Link, useHistory} from "react-router-dom";
import {API_URL} from "../../../constants";
import axios from 'axios';
import {Context} from "../../../app/store";
import {USER_LOGIN} from "../../../app/user/UserTypes";

function LoginPage() {

    const dispatch = useContext(Context)[1];

    const initialState = {
        error: null,
        loading: false,
    };
    const [state, setState] = useState(initialState);
    const history = useHistory();

    const onFinish = (values) => {
        setState({...state,loading: true});

        axios.post(API_URL + "/login", values)
            .then(response => {
                const token = response?.data?.data?.token;
                if(token && token.length > 0){
                    setState({
                        ...state,
                        error: null,
                        loading: false,
                    });
                    dispatch({type: USER_LOGIN, payload: {
                        "token":token,
                        "role":response?.data?.data?.role,
                        "userId":response?.data?.data?.userId
                    }});
                    history.push("/");
                }else{
                    setState({
                        ...state,
                        error: "Error connecting with the server, try again later",
                        loading: false,
                    });
                }
            })
            .catch(error =>{
                setState({
                    ...state,
                    loading: false,
                });
                if(error?.response?.data?.statusCode?.code === -19){
                    message.error("User not found or incorrect password");
                    return
                }
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg)
            })

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error(errorInfo);
    };

    return (
        <div className={"login-page"}>
            <div className="">
                {state.error && <Alert message={state.error} type="error" closable className="notification" />}
                <h3>Login</h3>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        labelAlign="left"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        labelAlign="left"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                        style={{marginBottom: '5px'}}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8 }}>
                        <Link to="/forgot" style={{display: 'block', width: '100%', textAlign: 'left', paddingLeft: 0}}>Forgot password</Link>
                    </Form.Item>

                    <Form.Item wrapperCol={{}}>
                        <Button type="primary" htmlType="submit" loading={state.loading}>
                            Submit
                        </Button>
                    </Form.Item>

                    <small>If you need to register please contact Mediatropy</small>
                </Form>
            </div>
        </div>
    )
}

export default LoginPage
