import React from 'react';
import CountryTable from './CountryTable'

function CountryPage(){
    return (
        <div>
            <h2>Countries</h2>
            <CountryTable/>

        </div>
    )
};

export default CountryPage;
