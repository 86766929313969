import React, {useEffect, useState} from 'react';
import clientAPI from "../../service/ApiService";
import {Alert, Input} from 'antd';
import Row from "./UserRow";
import Pagination from "../Common/Pagination";
import {getAllUsersEndpoint} from "../../service/RoleService";

function UserTable({refresh}) {

    const initialState = {
        page: 1,
        users: [],
        sizePerPage: 20,
        totalSize: 0,
        error: null,
        loading: false
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getUsers()
    }, [state.page, state.sizePerPage, refresh]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUsers = (search=null) => {
        setState({
            ...state,
            error: null,
            loading: true,
        });

        let requestUrl = getAllUsersEndpoint() +
            "?page=" + state.page +
            "&limit=" + state.sizePerPage +
            (search ? "&keyword=" + encodeURIComponent(search) : "");

        clientAPI.get(requestUrl)
            .then(response => {

                let users = response.data?.data;
                if(!users || users.length <= 0 ){
                    setState({...state,
                        users:[],
                        totalSize: 0,
                        page: state.page > 1 ? state.page-1 : state.page
                    });
                    return false
                }

                setState({
                    ...state,
                    error: null,
                    loading: false,
                    users: users,
                    totalSize: response.data.pagination.total
                })
            })
            .catch(error => {

                let msg = error?.response?.data?.statusCode?.msg
                setState({
                    ...state,
                    error: msg ?? null,
                    loading: false,
                    users: [],
                    totalSize: null
                })
            })
    }

    const goToPage = ((page) => {
        setState({
            ...state,
            page: page
        })
    });

    const { Search } = Input;
    const [timer, setTimer] = useState(null)


    const setSearch = (event) => {
        var searchText = event.target.value;

        if (timer != null) {
            clearTimeout(timer);
        }

        setTimer(
            setTimeout(() => {
                getUsers(searchText)
            }, 300)
        )
    }

    return (
        <div>
            {state.error && <Alert message={state.error} type="error" closable className="notification" />}
            <Search placeholder="Search user by id or name"
                    loading={state.loading}
                    onChange={(e) => setSearch(e)}
            />
            {state.users &&
            <div className={"table-wrapper ant-table"}>
                <table className="table">
                    <thead className={"ant-table-thead"}>
                    <tr>
                        <th scope="col" style={{ color:"black" }}>Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Activated</th>
                        <th scope="col">Role</th>
                    </tr>
                    </thead>
                    <tbody className={"ant-table-tbody"}>
                    {state.users?.map((user, index) =>
                        <Row key={index} user={user} />
                    )}
                    </tbody>
                </table>
                <Pagination
                    sizePerPage={state.sizePerPage}
                    totalSize={state.totalSize}
                    page={state.page}
                    goToPage={goToPage}
                />
            </div>
            }
        </div>
    )
}


export default UserTable;