import React, {useEffect, useState} from 'react';

import {Button, Comment, Drawer, List, message, Spin} from "antd";
import moment from 'moment';
import clientAPI from "../../../../service/ApiService";
import {getCommentEndpoint, getUserId} from "../../../../service/RoleService";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import {API_USER_COMMENTS} from "../../../../constants";

function CommentsDrawer({visible, setVisible, usp, refresh}) {

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [comments, setComments] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        if(usp)
            getComments();
    }, [usp]);// eslint-disable-line react-hooks/exhaustive-deps

    const getComments = () => {
        setLoading(true);
        let userId = parseInt(getUserId(), 10);
        clientAPI.get(getCommentEndpoint() + "/" + usp?.id)
            .then(response => {
                setLoading(false);
                let data = response.data?.data;
                if(!data || data.length <= 0 ){
                    setComments([]);
                    return false
                }

                setComments(data.map( (c) => {
                        return {
                            author: c?.User?.email,
                            content: (
                                <div dangerouslySetInnerHTML={{__html: c.body}} />
                            ),
                            datetime: (
                                <span>{moment(c.createdAt).format("DD MM YYYY hh:mm:ss")}</span>
                            ),
                            action: [(userId === c?.User.id && <Button danger type={"link"}
                                              className={"delete-comment"}
                                              onClick={()=>deleteComment(c)}>Delete</Button>)],
                        }
                    }
                ))
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    }

    const deleteComment = (comment) => {
        clientAPI.delete(API_USER_COMMENTS + "/" + comment.id)
            .then(response => {
                console.log("deleted");
                refresh();
                getComments();
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoadingSave(false);
            })
    }

    const saveComment = () =>{

        let data = {
            uspId: usp.id,
            body: value
        }

        setLoadingSave(true);
        clientAPI.post(API_USER_COMMENTS, data)
            .then(response => {
                setLoadingSave(false);
                setValue("");
                refresh();
                getComments();
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoadingSave(false);
            })
    }

    return (
        <Drawer
            closable={true}
            closeIcon={true}
            onClose={()=>setVisible(false)}
            title={"Comments"}
            placement={"right"}
            visible={visible}
            width={"40%"}
        >
            {loading && <Spin />}
            <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={item => (
                    <li>
                        <Comment
                            actions={item.action}
                            author={item.author}
                            content={item.content}
                            datetime={item.datetime}
                        />
                    </li>
                )}
            />
            <ReactQuill theme="snow" value={value} onChange={setValue}/>
            <Button type={"primary"} className={"save-comment"} onClick={()=>saveComment()}
                loading={loadingSave}
            >
                Save
            </Button>
        </Drawer>
    )
}


export default CommentsDrawer;