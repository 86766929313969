/* eslint-disable no-useless-computed-key */

import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, message, Modal, Select, Input} from "antd";
import clientAPI from "../../service/ApiService";
import {API_USER_TICKETS} from "../../constants";
import TextArea from "antd/es/input/TextArea";
import {getCategoriesEndpoint, getDetailUsersEndpoint, getHotelsEndpoint, isAdmin} from "../../service/RoleService";
import moment from "moment";
import axios from "axios";

function TicketModal({open, ticket, doRefresh, titleModal}){

    const [loading, setLoading] = useState(false);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [imageUrl, setImageUrl] = useState(null);
    const onFileInput = async (e) => {
        const timestamp = new Date().getTime();
        const file = e.target.files[0];
        const filename = file.name.split('.')[0].replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase() + `_${timestamp}`;
        const fileExtension = file.name.split('.').pop();

        await uploadImage(`${filename}.${fileExtension}`, file);
    }
    const uploadImage = async (filename, file) => {
        const options = { headers: { 'Content-Type': file.type } };

        try {
            const s3Urls = await axios.get(
                `${process.env.REACT_APP_API_URL}/ticket-files?filename=${filename}&contentType=${file.type}`
            ).then(response => response.data?.urls);

            if (!s3Urls.signedUrl) {
                console.log('signed url is not defined')
                throw new Error('S3 signed url is not defined');
            }

            await axios.put(s3Urls.signedUrl, file, options);

            setImageUrl(s3Urls.publicUrl);
        } catch (err) {
            console.error(`Error uploading image: ${err.message}`);
        }
    }

    useEffect(() => {
        if(open <= 0)
            return;
        setImageUrl(ticket?.attachment ? ticket?.attachment : null);
        showModal();

    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        form.resetFields();
        if (isAdmin()) {
            getAdminManagerHotel();
        }
        if (!isAdmin()) {
            getUserHotel();
        }
        getCategories();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (data) => {
        data.attachment = imageUrl ? imageUrl : "";
        data = {...data,
            deadline: data.deadline.format('YYYY-MM-DD')
        }

        setLoading(true);
        let request = {};
        if(ticket === null){
            request = clientAPI.post(API_USER_TICKETS, data);
        }else{
            request = clientAPI.put(API_USER_TICKETS + "/" + ticket.id, data);
        }
        request
            .then(response => {
                setLoading(false);
                setIsModalVisible(false);
                doRefresh();
            })
            .catch(error => {
                setLoading(false);
                message.error(error.response?.data?.data?.error?.message ?? "Undefined error")
            })
    };

    const onFinishFailed = () => {};

    const getCategories = () => {
        setCategoriesLoading(true);

        clientAPI.get(getCategoriesEndpoint())
            .then(response => {
                let resp = response.data?.data;
                if(!resp || resp.length <= 0 ){
                    setCategories([]);
                    return false
                }

                setCategories(resp);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setCategoriesLoading(false);
            })
    }

    const [userHotelLoading, setUserHotelLoading] = useState(false);
    const [userHotel, setUserHotel] = useState([]);
    const getUserHotel = () => {
        setUserHotelLoading(true);

        clientAPI.get(getDetailUsersEndpoint() + "/hotels")
            .then(response => {

                let hotels = response.data?.data;
                if(!hotels || hotels.length <= 0 ){
                    setUserHotel([])
                    return false
                }
                setUserHotel(hotels);
                setUserHotelLoading(false);
            })
            .catch(error => {
                message.error("Error loading the hotels");
                setUserHotelLoading(false);
            })
    }

    const [adminManagerLoading, setAdminManagerLoading] = useState(false);
    const [adminManagerHotel, setAdminManagerHotel] = useState([]);
    const getAdminManagerHotel = () => {
        setAdminManagerLoading(true);

        clientAPI.get(getHotelsEndpoint() + "?limit=10000&order=asc")
            .then(response => {
                let hotel = response?.data?.data;
                if(!hotel) return false;

                setAdminManagerHotel(hotel);
                setAdminManagerLoading(false);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setAdminManagerLoading(false);
            })
    }

    return (
        <Modal
            title={titleModal}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button type={"primary"} form="myForm" key="submit" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            ]}
        >
            <Form
                id="myForm"
                name="basic"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    ["title"]: ticket?.title,
                    ["description"]: ticket?.description,
                    ["hotelId"]: ticket?.hotelId,
                    ["typeId"]: ticket?.typeId,
                    ["deadline"]:  ticket?.deadline ? moment(ticket?.deadline) : null,
                    ["attachment"]: ticket?.attachment,
                }}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true }]}
                >
                    <Input
                        autoFocus={true}
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true }]}
                >
                    <TextArea/>
                </Form.Item>

                {isAdmin() &&
                    <Form.Item
                        label="Hotel"
                        name="hotelId"
                        rules={[{required: true}]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a hotel"
                            loading={adminManagerLoading}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {adminManagerHotel?.map((hotel, index) =>
                                <Option key={index} value={hotel.id}>{hotel.name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                }

                {!isAdmin() &&
                    <Form.Item
                        label="Hotel"
                        name="hotelId"
                        rules={[{required: true}]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a hotel"
                            loading={userHotelLoading}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {userHotel?.map((hotel, index) =>
                                <Option key={index} value={hotel.id}>{hotel.name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                }

                <Form.Item
                    label="Type"
                    name="typeId"
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a ticket type"
                        loading={categoriesLoading}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {categories?.map((category, index) =>
                            <Option key={index} value={category.id}>{category.name}</Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Deadline"
                    name="deadline"
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                    />
                </Form.Item>
                <Form.Item label="Attachment">

                <div className="header">
                    <input type="file" id="file_input" onChange={onFileInput} />
                    {imageUrl && <div className="result">
                        <br/>
                        <a href={imageUrl} className="image-url" target="_blankד">Uploaded File</a>
                    </div>
                    }
                </div>
                 </Form.Item>

            </Form>
        </Modal>
    )
};

export default TicketModal;
