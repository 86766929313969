import React, {useEffect, useState} from 'react';
import {Button, Col, Descriptions, message, Row, Spin, Tag} from 'antd';
import clientAPI from "../../service/ApiService";
import {API_USERS} from "../../constants";
import {Link, useHistory, useParams} from 'react-router-dom';
import UserModal from "./UserModal";
import UserHotelsTable from "./UserHotelsTable";
import {getDetailUsersEndpoint, isAdmin} from "../../service/RoleService";

function UserDetail() {

    const history = useHistory();
    const [userModal, setUserModal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [user, setTemplate] = useState(null)
    const {userId} = useParams();

    useEffect(() => {
        if(!userId)
            return;
        getUser()
    }, [userId]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        setLoading(true);

        clientAPI.get(getDetailUsersEndpoint() + "/" + userId)
            .then(response => {
                let user = response?.data?.data;
                if(!user){
                    return false
                }

                setTemplate(user);
                setLoading(false);
            })
            .catch(error => {
                let msg = error?.response?.data?.statusCode?.msg
                message.error(msg);
                setLoading(false);
            })
    };

    const deleteUser = () => {
        setLoading(true);

        clientAPI.delete(API_USERS + "/" + userId)
            .then(response => {
                setLoading(false);
                history.push("/users");
            })
            .catch(error => {
                setLoading(false);
                let status = error?.response?.data?.statusCode?.code;
                if(status === -6002){
                    message.error('You are trying to delete a user that has some information attached.');
                }else{
                    message.error('Error deleting the user');
                }
            })
    };

    const doRefresh = () => {
        getUser();
    }

    return (
        <div className={"detail-page"}>
            <Link to={"/users/"}>
                Back to Users
            </Link>
            {loading && <Spin/>}
            {user &&
                <React.Fragment>
                    <Row>
                        <Col span={8}>
                            <h2>User Detail</h2>
                        </Col>
                        { isAdmin() &&
                            <Col span={8} offset={8} className={"actions"}>
                                <Button onClick={() => setUserModal(userModal+1)}>Update</Button>
                                <Button danger onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteUser() } }>Delete</Button>
                            </Col>
                        }
                    </Row>

                    <Descriptions bordered>
                        <Descriptions.Item label="Id" span={1}>{user.id}</Descriptions.Item>
                        <Descriptions.Item label="Name" span={2}>{user.name}</Descriptions.Item>
                        <Descriptions.Item label="Enabled" span={1}>{ user.activated ? <Tag color="blue">Yes</Tag> : <Tag color="red">No</Tag> }</Descriptions.Item>
                        <Descriptions.Item label="Email" span={2}>{user.email}</Descriptions.Item>
                        <Descriptions.Item label="Role" span={1} style={{textTransform: 'capitalize'}}>
                            {user.role}
                        </Descriptions.Item>
                    </Descriptions>

                    <UserHotelsTable user={user} />

                </React.Fragment>
            }
            <UserModal
                user={user}
                open={userModal}
                doRefresh={doRefresh}
            />
        </div>
    );
}

export default UserDetail;
